<template>
  <div aria-hidden="true" class="flex items-center gap-2 @container">
    <div
      v-for="index in 10"
      :key="index"
      v-motion
      class="flex aspect-square basis-[10%] flex-col items-center justify-center rounded bg-black/20 text-white"
      :class="{ 'basis-[20%] rounded-lg !bg-black': index === roundedNps }"
      :initial="{ opacity: 0, scale: 0.8 }"
      :visibleOnce="{
        opacity: 1,
        scale: 1,
        transition: {
          duration: 150,
          delay: index * 50,
          type: 'spring',
          stiffness: 250,
          damping: 25,
          mass: 0.5,
        },
      }"
    >
      <p
        v-if="index === roundedNps"
        class="text-2xl font-extrabold @lg:text-5xl"
      >
        {{ nps }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
const properties = withDefaults(
  defineProps<{
    nps?: number | string;
  }>(),
  {
    nps: "9,6",
  },
);

const roundedNps = computed(() => {
  const parsedNps =
    typeof properties.nps === "string"
      ? Number.parseInt(properties.nps)
      : properties.nps;

  return Math.floor(parsedNps);
});
</script>
